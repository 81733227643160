import type { NuxtError } from '#app'
import type { ISearchProcedureResponse, ISearchProcedureResponseItem } from '@/types/types'
import { EventName, SearchProcedureOrdenation, SearchProcedureOrdenationMapping } from '@/types/enums'

interface ISearchProcedure {
  data: ComputedRef<any>
  pending: ComputedRef<boolean>
  error: ComputedRef<NuxtError | null>
}

const path = '/v2/recommendations/display-search'
export default function (term: Ref<string>, instanceKey: string = 'default', selected: Ref<ISearchProcedureResponseItem[]>): ISearchProcedure {
  const baseURL = useRuntimeConfig().public.api.awsGateway

  const { status, data, error } = useAsyncData<ISearchProcedureResponse | null>(`useSearchProcedures-${instanceKey}`, async () => {
    if (term.value.length < 2)
      return null

    const fetched = await $fetch<ISearchProcedureResponse>(path, { baseURL, query: { q: term.value } })

    track(EventName.ExamInput, {
      searchTerm: term.value,
      resultsQuantity: fetched.items.length,
    })

    return snakeToCamel(fetched)
  }, { watch: [term], server: false })

  return {
    data: computed(() => {
      if (data.value?.items?.length) {
        const slugs = uniq([
          ...selected.value.map(({ slug }: ISearchProcedureResponseItem) => slug),
          ...useProcedureSlug().value,
        ])

        return data.value.items.filter(({ slug }: ISearchProcedureResponseItem) => !slugs.includes(slug))
      }

      return []
    }),
    pending: computed(() => !!(status.value === 'pending' && term.value.length)),
    error: computed(() => error.value),
  }
}

export function useSearchProcedureOrdenation() {
  return useState('SearchProcedureOrdenation', () => SearchProcedureOrdenationMapping[SearchProcedureOrdenation.RELEVANCE])
}
